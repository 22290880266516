import { useEffect } from 'react'
import { Routes, Route, useNavigate, Link } from "react-router-dom";
import Cookies from 'js-cookie'

import Home from './Home.js';
import Report from './Report.js';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  let history = useNavigate();

  useEffect(() => {
    //Cookies.set('cmg_auth_info', "David:admin:0")
    let c = window.location.pathname
  
    if(typeof c !== 'undefined')
      history(c);
    else
      history("/")
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/report" element={<Report />} />
      </Routes>
    </div>
  );
}

export default App;
