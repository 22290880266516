import React, {useRef} from 'react';
import { utils, writeFile }  from 'xlsx'

import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import { useTable, useSortBy, useFilters, usePagination } from "react-table"
import { Filter, DefaultColumnFilter } from './Filters';
import { isMobile } from 'react-device-detect';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { BsArrowUpShort, BsArrowDownShort } from 'react-icons/bs';

const TableContainer = ({ columns, data, simple, mobile }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable({
    columns,
    data,
    defaultColumn: { Filter: DefaultColumnFilter },
    initialState: { pageIndex: 0, pageSize: 10 }
  }, useFilters, useSortBy, usePagination)

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? <BsArrowDownShort style={{ color: "#000" }}/> : <BsArrowUpShort style={{ color: "#000" }}/>) : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }
  
  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const handleOnExport = () => {
    var customData = [];

    data.map(item => {
      customData.push({
        ...item,
        date: new Intl.DateTimeFormat('es', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) }).format((new Date(item.date)).getTime())
      })
    })

    var wb = utils.book_new(),
    ws = utils.json_to_sheet(customData);

    utils.book_append_sheet(wb, ws, "Sessions");

    writeFile(wb, "MusicExportedData.xlsx")
  }

  return (
    <div>
    {!simple && 
      <Container>
        <Row>
          <Col>
            {headerGroups.map(headerGroup => (
              <div {...headerGroup.getHeaderGroupProps()}>
                <Container>
                  <Row>
                    {headerGroup.headers.map(column => (
                      <Col className={column.canFilter == true ? "" : "hide"} md={4}>
                        <div {...column.getHeaderProps()} align="left">
                          <span {...column.getSortByToggleProps()}>
                            {column.render("Header")}
                          </span>
                          <span><Filter column={column} /></span>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>
            ))}
          </Col>
          <Col md={ 3 } align="right">
            <Button onClick={ handleOnExport }> Export to xls</Button>
          </Col>
        </Row>
        <br/>
      </Container>
    }

    <br/>
    <Table className="table-style" striped hover responsive="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} className={ mobile ? "hide" : ""}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                <div {...column.getSortByToggleProps()}>
                  {column.render("Header")}
                  {generateSortingIndicator(column)}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {page.map(row => {
          var show = true;
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()} className={""}>{cell.render("Cell")}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>

    { !simple &&
      <Row style={{ width: isMobile ? "auto" : "auto", margin: "0 0 0 auto", textAlign: "center" }}>
        <Col>
          {/*
          <Button
            size="sm"
            color="primary"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>
          &nbsp;&nbsp;
          */}
          
          <Button
            size="sm"
            variant="warning"
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {"<"}
          </Button>
          
        </Col>
        <Col style={{ marginTop: 7, color: "#000", fontSize: "13px" }}>
          Página{" "}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>
        </Col>
        { !isMobile &&
        <Col>
          <Form.Control
            size="sm"
            type="number"
            min={1}
            style={{ width: 70, fontSize: "13px" }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>
        }
        <Col>
          <Form.Select size="sm" type="select" value={pageSize} style={{ fontSize: "13px" }} onChange={onChangeInSelect}>
            {[10, 25, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col>
          <Button size="sm" variant="warning" onClick={nextPage} disabled={!canNextPage}>
            {">"}
          </Button>

          {/*
          &nbsp;&nbsp;
          <Button
            size="sm"
            color="primary"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
          */}
        </Col>
      </Row>
    }
    </div>
  )
}

export default TableContainer