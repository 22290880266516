import { useState, useEffect } from 'react'
import axios from 'axios'
import { setCookie, getCookie, encode64 } from './Utils.js';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Modal from 'react-bootstrap/Modal'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import Iframe from 'react-iframe'

import music from './img/music1.jpg'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const LOGGIN_PATH = "https://crstl.com.mx/srv/login.do"
//const LOGGIN_PATH = "http://132.148.77.47/srv/login.do"
const u = "YWRtaW4=";
const p = "YWRtaW4=";

function Home() {
  const [ rol, setRol ] = useState('')
  const [ name, setName ] = useState('')
  const [ user, setUser ] = useState('')

  const [ userName, setUserName ] = useState('')
  const [ password, setPasswrod ] = useState('')

  const [ btnDisabled, setBtnDisabled ] = useState(false)
  const [ isActive, setIsActive ] = useState(false)
  const [ searchText, setSearchText ] = useState('Iniciar sesión')
  const [ isLogged, setIsLogged ] = useState(false)

  const [ showLoginMessage, setShowLoginMessage ] = useState(false)

  useEffect(() => {
    checkingLogin()
    checkingSession()
  }, []);

  const checkingSession = () => {
    setTimeout (() => {
      if(getCookie("cmg_auth_info"))
        checkingSession()
      else {
        clearTimeout()
        setIsLogged(false)
      }
    }, 900000)
  }

  const checkingLogin = () => {   
    var loginCookie = getCookie("cmg_auth_info");
    if(loginCookie) {
      var cookieInfo = loginCookie.replace(/['"]+/g, '').split(":");

      setName(cookieInfo[0])
      setUser(cookieInfo[1])
      setRol(cookieInfo[2])

      console.log(cookieInfo[0], cookieInfo[2], loginCookie)
      setIsLogged(true)
    }
  }

  const logOut = () => {

    axios.post(LOGGIN_PATH, {}, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'authorization': 'Authorization',
      'cmg_out': user
    }
    }).then(function(response) {
      var loginCookie = getCookie("cmg_auth_info");

      if(loginCookie)
        document.cookie = "cmg_auth_info=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
      
        setIsLogged(false)   
    }).catch(function(err) {
      setIsLogged(false)
      document.cookie = "cmg_auth_info=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
    });
  }

  const getLogin = (e) => {
    e.preventDefault();

    /*let usrBuff = atob(u);
    let usr = usrBuff.toString('ascii');
    let passBuff = atob(p);
    let pass = passBuff.toString('ascii');

    if(userName == usr && password == pass) {
      setIsActive(true)
      setBtnDisabled(true)
      setSearchText("Iniciando...")

      setCookie("cmg_auth_info", "David:admin:0")

      window.location.reload(false);
      //setIsActive(false)
      //setBtnDisabled(false)
      //setSearchText("Iniciar sesión")

      //setIsLogged(true)

    } else {
      setShowLoginMessage(true)
    }*/

    setIsActive(true)
    setBtnDisabled(true)
    setSearchText("Iniciando...")

    var userPass = encode64(userName + ":" + password);
    
    axios.post(LOGGIN_PATH, {}, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'authorization': 'Authorization ' + userPass
    }
    }).then(function(response) {
      var loginCookie = getCookie("cmg_auth_info");

      if(loginCookie)
        window.location.reload(false);
      else {
        setIsActive(false)
        setBtnDisabled(false)
        setSearchText("Iniciar sesión")
        setShowLoginMessage(true)
      }      
    }).catch(function(err) {
      setIsActive(false)
      setBtnDisabled(false)
      setSearchText("Iniciar sesión")
      setShowLoginMessage(true)
    });
  }

  return (
    <div className="App">

      <Modal show={showLoginMessage} onHide={() => setShowLoginMessage(false)}>
        <Modal.Header>
            Error
        </Modal.Header>
        <Modal.Body>
          <div>
            El usuario o la contraseña son incorrectas, favor de verificar sus credenciales.
          </div>
        </Modal.Body>
      </Modal>

      { !isLogged && 
        <div>
          <Container className="login-principal mw-class">
            <Row style={{ height: "100%" }}>
              <Col md={ 6 } className="p-0">
                <div className="login-image mw-class">
                </div>
              </Col>
              <Col md={ 6 }>
                <div align="center" className="loginForm">
                  <div className="loginContainer">

                    <div className="login-title">
                      Cristal Sounds
                    </div>
                    <br/>
                    <div className="login-subtitle">
                      Bienvenido al reproductor de música de Cristal
                    </div>
                    <br/>

                    <Form style={{ padding: "0 25px" }} onSubmit={ getLogin }>

                      <FloatingLabel controlId="floatingInput" label="Usuario" className="mb-3">
                        <Form.Control required type="text" placeholder="Usuario" value={userName} onChange={ (event) => setUserName(event.target.value) }/>
                      </FloatingLabel>

                      <FloatingLabel controlId="floatingPassword" label="Contraseña">
                        <Form.Control required type="password" placeholder="Contraseña" value={password} onChange={ (event) => setPasswrod(event.target.value) }/>
                      </FloatingLabel>

                      <br/>
                      <br/>
                      <Button variant="warning" type="submit" disabled={ btnDisabled }>
                        <Spinner
                          className={isActive ? '' : 'hide'}
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        { searchText }
                      </Button>
                      <br/>
                      <br/>
                    </Form>

                    <br/>
                    <br/>

                    <div className="login-footer">¿Tienes alguna duda? Por favor comunicate a este correo electrónico test@test.com y dinos en que podemos ayudarte</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      }

      { isLogged && 
        <div className="App-body">
          <Navbar expand="lg" className="navbar-custom">
            <Container>
              <Navbar.Brand href="#home" className="brand-style">Cristal Sounds</Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                </Nav>
                <Nav>
                  { rol === "0" && 
                    <Nav.Link href="/" style={{ color: "#fff" }}>
                      Music
                    </Nav.Link> }
                  { rol === "0" && 
                    <Nav.Link href="/report" style={{ color: "#fff" }}>
                      Reporte
                    </Nav.Link> 
                  }
                  <Nav.Link onClick={ logOut } style={{ color: "#fff" }}>Cerrar sesión</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <br/><br/><br/>
          <Container>
            <Row style={{ height: "100%" }} className="align-items-center">
              <Col>
                <div align="center">
                  <div className="music-instructions">
                    ¡Bienvenido {name}!
                  </div>
                  <div className="music-sub-instructions">
                    No olvides darle play
                  </div>
                  <br/>
                  <div className="music-subtitle">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
                  </div>
                </div>
              </Col>
              <Col>
                <div align="center">
                  {/*<Iframe url="https://sc10.conectarhosting.com/proxy/zoomradio4?mp=/stream"*/}
                  <Iframe url="https://cast3.my-control-panel.com/proxy/zoomradio4?mp=/stream"
                    width="450px"
                    height="450px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"/>
                </div>
              </Col>
            </Row>
          </Container>
          <br/>
          <br/>
        </div>
      }
    </div>
  );
}

export default Home;
