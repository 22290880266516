import { useState, useEffect } from 'react'
import axios from 'axios'
import { setCookie, getCookie, isToday } from './Utils.js';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Table from 'react-bootstrap/Table'

import { isMobile } from 'react-device-detect';
import TableContainer from "./TableContainer"

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

const LOGGIN_PATH = "https://crstl.com.mx/srv/login.do"
const SEARCH_PATH = "https://crstl.com.mx/srv/api/getReport/"
//const LOGGIN_PATH = "http://132.148.77.47/srv/login.do"
//const SEARCH_PATH = "http://132.148.77.47/srv/api/getReport/"

function Report() {
  const [ rol, setRol ] = useState('')
  const [ name, setName ] = useState('')
  const [ user, setUser ] = useState('')

  const [ btnDisabled, setBtnDisabled ] = useState(false)
  const [ isActive, setIsActive ] = useState(false)
  const [ searchText, setSearchText ] = useState('Buscar')
  const [ isLogged, setIsLogged ] = useState(false)
  const [ startDate, setStartDate ] = useState(new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }))
  const [ endDate, setEndDate ] = useState(new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }))
  const [ data, setData ] = useState([])

  /*const data = [ 
    { 
      id: 1,
      date: (new Date('01-05-2022')).getTime(),
      user: 'Sucursal 1',
      login: '9:00',
      logout: '19:00',
      time: 10
    },
    { 
      id: 2,
      date: (new Date('02-05-2022')).getTime(),
      user: 'Sucursal 2',
      login: '9:00',
      logout: '19:00',
      time: 10
    },
    { 
      id: 3,
      date: (new Date('03-05-2022')).getTime(),
      user: 'Sucursal 3',
      login: '9:00',
      logout: '19:00',
      time: 10
    },
    { 
      id: 4,
      date: (new Date('04-05-2022')).getTime(),
      user: 'Sucursal 4',
      login: '9:00',
      logout: '19:00',
      time: 10
    },
    { 
      id: 5,
      date: (new Date()).getTime(),
      user: 'Sucursal 5',
      login: '9:00',
      logout: '19:00',
      time: 10
    }
  ];*/

  const columns = [ {
    accessor: 'date',
    Header: 'Fecha',
    Cell: ({ cell }) => {
      const { value } = cell;
      return (
        <div >
          {new Intl.DateTimeFormat('es', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) }).format(new Date(value))}
        </div>
      );
    },
    disableFilters: true
  }, {
    accessor: 'user',
    Header: 'Sucursal',
  }, {
    accessor: 'login',
    Header: 'Login',
    disableFilters: true
  }, {
    accessor: 'logout',
    Header: 'Logout',
    disableFilters: true
  }, {
    accessor: 'time',
    Header: 'Tiempo en sessión',
    Cell: ({ cell }) => {
      const { value } = cell;
      return (
        <div >
          {value + " hrs"}
        </div>
      );
    },
    disableFilters: true
  }];

  useEffect(() => {
    //checkingLogin()
  }, []);

  const checkingLogin = () => {   
    var loginCookie = getCookie("cmg_auth_info");
    if(loginCookie) {
      var cookieInfo = loginCookie.replace(/['"]+/g, '').split(":");

      setName(cookieInfo[0])
      setUser(cookieInfo[1])
      setRol(cookieInfo[2])
    } else {
      window.location.href="/"
    }
  }

  const getSearch = (e) => {
    e.preventDefault();

    setIsActive(true)
    setBtnDisabled(true)
    setSearchText("Buscando...")

    var localeStart = new Date(startDate).getTime()
    //localeStart = localeStart.setDate(localeStart.getDate() - 1);
    var localeEnd = new Date(endDate)
    localeEnd = localeEnd.setDate(localeEnd.getDate() + 1);
    //localestart = new Date(localestart).getTime()

    //console.log("searching", startDate, localeStart, localeEnd)
    
    //axios.get(SEARCH_PATH + new Date(startDate).toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }).getTime() + "/" + new Date(endDate).toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }).getTime(), {}, {
    axios.get(SEARCH_PATH + localeStart + "/" + localeEnd, {}, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
    }).then(function(response) {
      console.log("result", response.data)
      var json = response.data
      var dataTemp = []
      
      json.map(item => {
        const loginDate = new Date(item.login);
        const logoutDate = new Date(item.logout);

        const loginHour = loginDate.getHours() + ':' + (loginDate.getMinutes() < 10 ? '0' + loginDate.getMinutes() : loginDate.getMinutes());
        const logoutHour = logoutDate.getHours() + ':' + (logoutDate.getMinutes() < 10 ? '0' + logoutDate.getMinutes() : logoutDate.getMinutes());

        dataTemp.push({
          id: item.sessionId,
          date: (new Date(item.login)).getTime(),
          user: item.user.name,
          login: loginHour + ' hr',
          logout: isToday(new Date(item.login)) && item.logout == null ? "-" : logoutHour + ' hr',
          time: isToday(new Date(item.login)) && item.logout == null ? "-" : Math.ceil(item.sessionTime)
        })
      })

      setData(dataTemp)
      setIsActive(false)
      setBtnDisabled(false)
      setSearchText("Buscar")

    }).catch(function(err) {
      setIsActive(false)
      setBtnDisabled(false)
      setSearchText("Buscar")
    });
  }

  const logOut = () => {
    axios.post(LOGGIN_PATH, {}, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'authorization': 'Authorization',
        'cmg_out': user
      }
      }).then(function(response) {
        var loginCookie = getCookie("cmg_auth_info");
  
        if(loginCookie)
          document.cookie = "cmg_auth_info=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
        
          setIsLogged(false)
          window.location.href="/"   
      }).catch(function(err) {
        setIsLogged(false)
        document.cookie = "cmg_auth_info=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
        window.location.href="/" 
      });
  }

  return (
    <div className="App-body">
      <Navbar expand="lg" className="navbar-custom">
        <Container>
          <Navbar.Brand href="#home" className="brand-style">Cristal Sounds</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
            </Nav>
            <Nav>
              { rol === "0" && 
                <Nav.Link href="/" style={{ color: "#fff" }}>
                  Music
                </Nav.Link> }
              { rol === "0" && 
                <Nav.Link href="/report" style={{ color: "#fff" }}>
                  Reporte
                </Nav.Link> 
              }
              <Nav.Link onClick={ logOut } style={{ color: "#fff" }}>Cerrar sesión</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div>
        <Container>
          <Row style={{ height: "100%" }}>
            <Col md={ 12 } className="p-0">
              <div>
                <br/><br/>
                <div className="music-instructions">
                  Reporte de Logeos
                </div>
                <br/>
                <Form style={{ width: '500px' }} onSubmit={ getSearch }>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>From</Form.Label>
                        <Form.Control type="date" name="startDate" value={startDate} onChange={ (e) => setStartDate(e.target.value) }/>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>To</Form.Label>
                        <Form.Control type="date" name="endDate" value={endDate} onChange={ (e) => setEndDate(e.target.value) }/>
                      </Form.Group>
                    </Col>
                    <Col style={{ position: "relative" }}>
                      <Button variant="primary" type="submit" style={{ position: "absolute", bottom: "15px" }} disabled={ btnDisabled }>
                        <Spinner
                          className={isActive ? '' : 'hide'}
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        { searchText }
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <br/>
                { data &&
                  <TableContainer columns={ columns } data={data} simple={false} mobile={isMobile} />
                }
                <br/>
                <br/>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Report;
